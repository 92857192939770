<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="metrologicalMonitor">
			<template #ops>
				<el-button type="primary" @click="handleExport">导 出</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search
					width="300px"
					key-word-width="103px"
					:key-word-option="keyWordOption"
					:key-word.sync="keyWord"
					:search.sync="form.key"
				></gc-custom-search>
				<gc-custom-search keyWord="客户/站点名称" width="300px" :search.sync="form.userName"></gc-custom-search>
				<gc-custom-search
					keyWord="客户类型"
					width="300px"
					type="select"
					:search.sync="form.userType"
					:searchOption="options.userType"
					:search-option-key="{ label: 'name', value: 'defaultValue' }"
					:needAllForSearch="true"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button
						type="primary"
						@click="getList(1, apiMetrologicalMonitorList, null, keyWord)"
						v-click-blur
					>
						查 询
					</el-button>
					<el-button @click="reset(apiMetrologicalMonitorList, null, keyWord)" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-metrological-monitor`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="
						currentPage => {
							currentPageChange(currentPage, apiMetrologicalMonitorList, null, keyWord)
						}
					"
					@size-change="
						size => {
							page.size = size
							currentPageChange(1, apiMetrologicalMonitorList, null, keyWord)
						}
					"
				></gc-table>
			</div>
		</div>
	</div>
</template>

<script>
import { apiMetrologicalMonitorList } from '@/apis/alarm.api.js'
import common from '../mixins/common.js'
import deviceTypeOption from '../mixins/deviceTypeOption.js'
import { exportReport } from '@/apis/statisticAnalysis.api'
import { isBlank } from '@/utils/validate.js'

export default {
	name: 'metrologicalMonitor',
	mixins: [common, deviceTypeOption],
	data() {
		return {
			form: {
				key: null, //设备编号/用户号
				userName: null, //名称（客户/站点名称）
				userType: null, //客户类型
			}, //筛选条件
			columns: [],
			options: {},
			keyWordOption: [
				{
					label: '设备编号',
					value: 'deviceNo',
				},
				{
					label: '用户号',
					value: 'userNo',
				},
			],
			keyWord: 'deviceNo',
		}
	},
	mounted() {
		this.$set(this.options, 'userType', this.$store.getters.dataList.userType || [])
		// 默认请求第一页数据
		this.getList(1, apiMetrologicalMonitorList, null, this.keyWord)
	},
	methods: {
		apiMetrologicalMonitorList,
		handleExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			this.$toast('下载中，请稍后...')
			let params = {}
			for (let key in this.form) {
				if (!isBlank(this.form[key])) {
					if (key === 'key') {
						params[this.keyWord] = this.form[key]
					} else {
						params[key] = this.form[key]
					}
				}
			}
			const data = {
				method: 'post',
				url: '/monitor/report/measurement-data/export/excel',
				fileName: '计量监控.xlsx',
				data: {
					...params,
					tenantType: this.$store.getters.userInfo.tenantType || '0',
				},
			}
			exportReport(data, 'excel', '', this.$toast)
		},
	},
	computed: {
		pressureUnitName() {
			return this.$store.getters.pressureUnitName
		},

		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},

	watch: {
		pressureUnitName: {
			handler() {
				this.columns = [
					{
						key: 'deviceNo',
						name: '设备编号',
						fixed: 'left',
						dragDisable: true,
						disabledSelect: true,
					},
					{
						key: 'deviceName',
						name: '设备名称',
					},
					{
						key: 'userNo',
						name: '用户号',
					},
					{
						key: 'userName',
						name: '客户/站点名称',
					},
					{
						key: 'dataTime',
						name: '数据日期',
					},
					{
						key: 'standardCumulativeFlow',
						name: '标况总量（m³）',
					},
					{
						key: 'workCumulativeFlow',
						name: '工况总量（m³）',
					},
					{
						key: 'standardInstantaneousFlow',
						name: '标况流量（m³/h）',
					},
					{
						key: 'workInstantaneousFlow',
						name: '工况流量（m³/h）',
					},
					{
						key: 'pressure',
						name: `压力（${this.pressureUnitName}）`,
					},
					{
						key: 'temperature',
						name: '温度（℃）',
					},
					{
						key: 'voltage',
						name: '电压（V）',
					},
					{
						key: 'remainingQuantity',
						name: '剩余量（m³）',
					},
					{
						key: 'remainingAmount',
						name: '剩余金额（元）',
					},
				]
			},
			immediate: true,
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
</style>
